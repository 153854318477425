import React, { FormEventHandler } from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  IconButton,
  Zoom,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Close } from '@mui/icons-material';
import { useSmallScreenDetector } from '../hooks';

interface DialogProps extends MuiDialogProps {
  onClose?: VoidFunction;
  onSubmit?: FormEventHandler;
  title?: string;
  actions?: JSX.Element;
  fullWidth?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export const Dialog: React.FC<DialogProps> = ({
  onClose,
  onSubmit,
  title,
  actions,
  fullWidth = true,
  children,
  ...props
}) => {
  const fullScreen = useSmallScreenDetector('md');

  return (
    <MuiDialog
      {...props}
      TransitionComponent={Transition}
      // @ts-ignore
      PaperProps={{ component: onSubmit ? 'form' : undefined, onSubmit }}
      keepMounted
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      scroll="body"
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
};
