import { Box, LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { styled } from '@mui/material/styles';

const LoadingScreenContainer = styled('div')(({ theme }) => ({
  height: '100vh',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
}));

export const LoadingScreen: React.FC = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <LoadingScreenContainer>
      <Box width={400}>
        <LinearProgress sx={{ borderRadius: 3, overflow: 'hidden' }} />
      </Box>
    </LoadingScreenContainer>
  );
};
