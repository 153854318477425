import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import React from 'react';

import { useSettings } from '@textpony/theme';
import { createTheme } from './theme';

export const ThemeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { settings } = useSettings();

  const theme = createTheme(settings);

  // Setting rtl/ltr correctly: https://stackoverflow.com/questions/62799638/material-ui-textfield-not-affected-with-the-rtl-direction
  const cacheLtr = createCache({
    key: 'muiltr',
  });

  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  return (
    <CacheProvider value={settings.direction === 'rtl' ? cacheRtl : cacheLtr}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </CacheProvider>
  );
};
