import { AxiosError, AxiosResponse } from 'axios';
import set from 'lodash/set';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryOptions } from '../query';
import { Invoice, api, useCurrentUser } from '@textpony/interface';

export const useInvoices = (
  queryOptions: QueryOptions,
  options: UseQueryOptions<Invoice[], AxiosError> = {}
) => {
  const { filter, search, limit = 10, offset, sort } = queryOptions;
  const params = { limit };
  if (filter) {
    set(params, 'filter', filter);
  }
  if (sort) {
    set(params, 'sort', sort);
  }
  if (offset) {
    set(params, 'offset', offset);
  }
  if (search) {
    set(params, 's', JSON.stringify({ name: { $cont: search } }));
  }

  const cacheKey = ['users', params];

  const user = useCurrentUser();

  return useQuery<Invoice[], AxiosError>(
    cacheKey,
    () => {
      return api['users']
        .get(`/${user?.id}/invoices`)
        .then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
