import { api, Template } from '@textpony/interface';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useSaveTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation<Template, AxiosError, Partial<Template>>(
    async (templateData) => {
      const body = {
        ...templateData,
      };

      const request = body.id
        ? api['templates'].patch(`/${templateData.id}`, body)
        : api['templates'].post(``, body);

      return request.then((r: AxiosResponse) => r.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('templates');
      },
    }
  );
};
