import { api } from '@textpony/interface';
import { useMutation, useQueryClient } from 'react-query';
import { PaymentMethod } from '@textpony/interface';

export const useDeletePaymentMethod = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (paymentMethod: PaymentMethod) =>
      api['payment-methods'].delete(`/${paymentMethod.id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['payment-methods']);
      },
    }
  );
};
