import React, { ChangeEvent, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  label: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  options: Option[];
  values: string[];
}

export const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const { label, handleChange, options, values = [], ...other } = props;
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Button
        color="inherit"
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={handleOpenMenu}
        ref={anchorRef}
        {...other}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleCloseMenu}
        open={openMenu}
        PaperProps={{ style: { width: 250 } }}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.includes(value)}
                  onChange={(event) => handleChange(event, value)}
                  value={value}
                />
              }
              label={label}
              sx={{
                flexGrow: 1,
                mr: 0,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
