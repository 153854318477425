import { AxiosError, AxiosResponse } from 'axios';
import set from 'lodash/set';
import { useQuery, UseQueryOptions } from 'react-query';
import { Message } from '../../types';
import { api } from '@textpony/interface';
import { QueryOptions } from '../query';

interface MessagesList {
  data: Message[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export const useMessages = (
  queryOptions: QueryOptions & { id?: string },
  options: UseQueryOptions<MessagesList, AxiosError> = {}
) => {
  const { id, filter, search, limit = 10, offset, sort } = queryOptions;
  const params = { limit };
  if (filter) {
    set(params, 'filter', filter);
  }
  if (sort) {
    set(params, 'sort', sort);
  }
  if (offset) {
    set(params, 'offset', offset);
  }
  if (search) {
    set(params, 's', JSON.stringify({ name: { $cont: search } }));
  }

  const endpoint = id ? `/${id}` : '';

  const cacheKey = ['messages', endpoint, params];

  return useQuery<MessagesList, AxiosError>(
    cacheKey,
    () => {
      return api['messages']
        .get(endpoint, { params })
        .then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
