import axios, { AxiosError } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { history, IToken, localStorage } from '@textpony/interface';

import { environment } from '../environments/enviroment';

export const axiosInstance = axios.create({
  baseURL: environment.apiUrl + environment.apiPrefix,
});

axiosInstance.interceptors.request.use((config) => {
  config.params && delete config.params.queryKey; // remove
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const refreshAuthLogic = async (failedRequest: any) => {
  let tokens: IToken = localStorage.get('accessTokens');
  if (tokens) {
    try {
      localStorage.rm('accessTokens');
      const tokenRefreshResponse = await axiosInstance.post(
        '/refresh-tokens',
        {
          refreshToken: tokens.refresh.token,
        },
        {
          data: { skipAuthRefresh: true },
        }
      );
      tokens = tokenRefreshResponse.data;
      localStorage.set('accessTokens', tokens);
      failedRequest.response.config.headers.Authorization = `Bearer ${tokens.access.token}`;
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${tokens.access.token}`;
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err.response?.status === 401) {
        history.push('/');
      }
    }
  }
  return Promise.resolve();
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export default axiosInstance;
