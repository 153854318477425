import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React from 'react';
import { styled } from '@mui/material/styles';

const HorizontalSpacer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  '& > *': {
    marginRight: theme.spacing(2),
  },
  '& > *:last-child': {
    marginRight: 0,
  },
}));

interface ToolbarProps {
  extraComponents?: React.FC[];
}

export default function Toolbar({ extraComponents = [] }: ToolbarProps) {
  return (
    <GridToolbarContainer sx={(theme) => ({ marginTop: theme.spacing(1) })}>
      <HorizontalSpacer>
        <GridToolbarColumnsButton
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
        <GridToolbarFilterButton
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
        {extraComponents.map((ToolbarComponent, i) => (
          <ToolbarComponent key={i} />
        ))}
      </HorizontalSpacer>
    </GridToolbarContainer>
  );
}
