import React from 'react';
import { Box, Typography } from '@mui/material';
import FormContainer from './FormContainer';

export const AuthLayout: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        backgroundColor: 'background.default',
      }}
    >
      <Box flex="1 0 auto" component="main">
        <FormContainer>{children}</FormContainer>
      </Box>
      <Box
        component="footer"
        sx={{
          borderTopColor: 'divider',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="caption"
          align="center"
        >
          © 2022 TextPhony All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};
