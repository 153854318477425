import { AxiosError, AxiosResponse } from 'axios';
import set from 'lodash/set';
import { useQuery, UseQueryOptions } from 'react-query';
import { api, List } from '@textpony/interface';
import { QueryOptions } from '../query';

interface ListsList {
  data: List[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export const useLists = (
  queryOptions: QueryOptions,
  options: UseQueryOptions<ListsList, AxiosError> = {}
) => {
  const { search, limit = 10, offset, sort } = queryOptions;
  const params = { limit };
  if (sort) {
    set(params, 'sort', sort);
  }
  if (offset) {
    set(params, 'offset', offset);
  }
  if (search) {
    set(params, 's', JSON.stringify({ name: { $cont: search } }));
  }

  const cacheKey = ['lists', params];

  return useQuery<ListsList, AxiosError>(
    cacheKey,
    () => {
      return api['lists'].get('', params).then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
