import React, { useMemo } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Scrollbar } from '@textpony/interface';
import SideBarHeader from './SideBarHeader';
import { getSections } from './sections';
import SideBarDrawer from './SideBarDrawer';
import SideBarSection from './SideBarSection';

interface Props {
  open: boolean;
  onClose: () => void;
}

const SideBar: React.FC<Props> = ({ open, onClose }) => {
  const sections = useMemo(() => getSections(), []);

  return (
    <SideBarDrawer open={open} onClose={onClose}>
      <Scrollbar
        sx={{
          height: '100%',
        }}
      >
        <Box height="100%" display="flex" flexDirection="column">
          <SideBarHeader />
          <Divider />
          <Box flexGrow="1" my={5}>
            {sections.map((section) => {
              return <SideBarSection key={section.title} section={section} />;
            })}
          </Box>
          <Divider />
        </Box>
      </Scrollbar>
    </SideBarDrawer>
  );
};

export default SideBar;
