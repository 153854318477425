import { SnackbarKey, useSnackbar } from 'notistack';
import { Button } from '@mui/material';

export function useConfirmation() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const confirmAction = (
    message: JSX.Element | string,
    onConfirm: VoidFunction,
    onCancel?: VoidFunction
  ) =>
    enqueueSnackbar(message, {
      action: (snackbarId: SnackbarKey) => (
        <>
          <Button
            variant="text"
            onClick={() => {
              onCancel && onCancel();
              closeSnackbar(snackbarId);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              onConfirm();
              closeSnackbar(snackbarId);
            }}
          >
            Confirm
          </Button>
        </>
      ),
    });

  return { confirmAction };
}
