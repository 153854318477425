import { AxiosError, AxiosResponse } from 'axios';
import set from 'lodash/set';
import { useQuery, UseQueryOptions } from 'react-query';
import { api, Contact } from '@textpony/interface';
import { QueryOptions } from '../query';

interface ContactsList {
  data: Contact[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export const useContacts = (
  queryOptions: QueryOptions,
  options: UseQueryOptions<ContactsList, AxiosError> = {}
) => {
  const { limit = 10, offset, sort } = queryOptions;
  const params = { limit, join: 'lists' };
  if (sort) {
    set(params, 'sort', sort);
  }
  if (offset) {
    set(params, 'offset', offset);
  }

  const cacheKey = ['contacts', params];

  return useQuery<ContactsList, AxiosError>(
    cacheKey,
    () => {
      return api['contacts'].get('', params).then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
