import { Box, Typography } from '@mui/material';
import React from 'react';
import { useAuth, useBalance } from '@textpony/interface';

const SideBarHeader: React.FC = () => {
  const { user } = useAuth();
  const fullName = [user?.firstName, user?.lastName]
    .filter((val) => !!val)
    .join(' ');

  const { data: balance } = useBalance({ keepPreviousData: true });

  return (
    <Box px={2} py={5}>
      <Box
        py="11px"
        px={3}
        borderRadius={1}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? 'action.hover' : 'neutral.100',
        }}
      >
        <Typography
          sx={{
            color: (theme) =>
              theme.palette.mode === 'dark' ? 'inherit' : 'neutral.600',
          }}
          variant="subtitle1"
        >
          {fullName}
        </Typography>
        <Typography
          sx={{
            color: (theme) =>
              theme.palette.mode === 'dark' ? 'neutral.400' : 'neutral.600',
          }}
          variant="body2"
        >
          Credits : {balance?.available}
        </Typography>
      </Box>
    </Box>
  );
};

export default SideBarHeader;
