import { api } from '@textpony/interface';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export const useDeleteMessages = () => {
  return useMutation<void, AxiosError, string[]>((ids: string[]) => {
    /**
     * @todo Instead of the multiple API requests probably is better to implement
     * batch action to delete contacts
     */
    const requests = ids.map((id) => api['messages'].delete(`/${id}`));
    return Promise.all(requests).then(() => undefined);
  });
};
