import { AxiosError } from 'axios';
import set from 'lodash/set';
import { useQuery, UseQueryOptions } from 'react-query';
import { ConversationMessage, MessageStatus, MessageType } from '../../types';
import { QueryOptions } from '../query';

interface ConversationMessagesList {
  data: ConversationMessage[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

const MESSAGES = [
  {
    id: '1',
    recipient: 'John Doe',
    number: '+56912345678',
    credits: 1,
    createdAt: '2023-06-16T15:01:49.499Z',
    type: MessageType.sms,
    status: MessageStatus.sent,
    interaction: 'sent',
  },
  {
    id: '2',
    recipient: 'John Doe',
    number: '+56912345678',
    credits: 2,
    createdAt: '2023-06-16T15:01:49.499Z',
    type: MessageType.whatsapp,
    status: MessageStatus.failed,
    interaction: 'received ',
  },
  {
    id: '3',
    recipient: 'John Doe',
    number: '+56912345678',
    credits: 2,
    createdAt: '2023-06-16T15:01:49.499Z',
    type: MessageType.telegram,
    status: MessageStatus.sent,
    interaction: 'sent',
  },
];

export const useConversationMessages = (
  queryOptions: QueryOptions,
  options: UseQueryOptions<ConversationMessagesList, AxiosError> = {}
) => {
  const { filter, search, limit = 10, offset, sort } = queryOptions;
  const params = { limit };
  if (filter) {
    set(params, 'filter', filter);
  }
  if (sort) {
    set(params, 'sort', sort);
  }
  if (offset) {
    set(params, 'offset', offset);
  }
  if (search) {
    set(params, 's', JSON.stringify({ number: { $cont: search } }));
  }

  if (filter) {
    set(params, 'status', filter.status);
  }

  if (filter) {
    set(params, 'updatedAt', {
      $gte: filter.startDate,
      $lte: filter.endDate,
    });
    set(params, 'interaction', filter.interaction);
  }

  const cacheKey = ['conversation-messages', params];

  return useQuery<ConversationMessagesList, AxiosError>(
    cacheKey,
    () => {
      /** @todo Connect to API to get conversation messages */
      return new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              data: MESSAGES,
              page: 1,
              total: MESSAGES.length,
              count: MESSAGES.length,
              pageCount: 1,
            }),
          700
        );
      });
    },
    {
      ...options,
    }
  );
};
