import React, { useCallback, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider, QueryParamsProvider } from '@textpony/interface';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { SettingsProvider, ThemeProvider } from '@textpony/theme';
import { ReactQueryDevtools } from 'react-query/devtools';
import routes, { renderRoutes } from '@panel/routes';
import { ErrorsList } from '@panel/app/ErrorsList';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

type AlertErrorFunc = (error: string | JSX.Element) => void;

const errorBoundaryResolver = (error: any) => error?.status !== 400;

const REPORTABLE_ERROR_STATUSES = [400, 401, 422];

const queryClientFactory = (alertError: AlertErrorFunc) => {
  const errorHandler = (error: any) => {
    if (!REPORTABLE_ERROR_STATUSES.includes(error?.status)) {
      return;
    }

    let messages: string[] = error?.data?.message || [];
    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    if (messages.length === 0) {
      return;
    }
    alertError(
      <ErrorsList>
        {messages.map((message, key) => (
          <li key={key}>{message}</li>
        ))}
      </ErrorsList>
    );
  };

  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        useErrorBoundary: true,
        onError: errorHandler,
        retry: false,
      },
      mutations: {
        useErrorBoundary: errorBoundaryResolver,
        onError: errorHandler,
        retry: false,
      },
    },
  });
};

const InnerApp: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const alertError: AlertErrorFunc = useCallback((error) => {
    enqueueSnackbar(error, { variant: 'error' });
  }, []);

  const queryClient = useMemo(() => queryClientFactory(alertError), []);

  return (
    <QueryClientProvider client={queryClient}>
      {renderRoutes(routes)}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

const App: React.FC = (): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <SettingsProvider>
        <ThemeProvider>
          <BrowserRouter>
            <AuthProvider>
              <QueryParamsProvider
                defaultParams={{
                  page: 0,
                  limit: 50,
                  sort: undefined,
                  filter: undefined,
                }}
              >
                <SnackbarProvider>
                  <InnerApp />
                </SnackbarProvider>
              </QueryParamsProvider>
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
};

export default App;
