import {
  gridColumnDefinitionsSelector,
  GridPanelContent,
  GridPanelWrapper,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import * as React from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const ColumnBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1px 8px 1px 7px',
}));

const ColumnSwitch = styled(Switch)(() => ({
  marginRight: 4,
}));

export default function ColumnsPanel() {
  const apiRef = useGridApiContext();
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector).filter(
    ({ type }) => type !== 'actions'
  );

  const toggleColumn = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { name } = event.target as HTMLInputElement;
      const column = apiRef.current.getColumn(name);
      apiRef.current.setColumnVisibility(name, !!column.hide);
    },
    [apiRef]
  );

  return (
    <GridPanelWrapper>
      <GridPanelContent>
        <Box sx={{ padding: '8px 0px 8px 8px' }}>
          {columns
            .filter(
              (column) =>
                column.field !== '__check__' && column.hideable !== false
            )
            .map((column) => (
              <ColumnBox key={column.field}>
                <FormControlLabel
                  control={
                    <ColumnSwitch
                      checked={!column.hide}
                      onClick={toggleColumn}
                      name={column.field}
                      color="primary"
                      size="small"
                    />
                  }
                  label={column.headerName || column.field}
                />
              </ColumnBox>
            ))}
        </Box>
      </GridPanelContent>
    </GridPanelWrapper>
  );
}
