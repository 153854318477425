import { Drawer } from '@mui/material';
import React from 'react';
import { useSmallScreenDetector } from '@textpony/interface';

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
}

const SideBarDrawer: React.FC<Props> = ({ open, onClose, children }) => {
  const smallScreen = useSmallScreenDetector();

  return (
    <Drawer
      anchor="left"
      variant={smallScreen ? 'temporary' : 'permanent'}
      open={smallScreen ? open : true}
      onClose={smallScreen ? onClose : undefined}
      PaperProps={{
        sx: {
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
    >
      {children}
    </Drawer>
  );
};

export default SideBarDrawer;
