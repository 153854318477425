import { Home as HomeIcon } from '@textpony/interface';
import ContactsIcon from '@mui/icons-material/Contacts';
import PhoneIcon from '@mui/icons-material/Phone';
import { SidebarSection } from './interfaces';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const getSections = (): SidebarSection[] => [
  {
    title: 'Textpony',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <HomeIcon fontSize="small" />,
      },
      {
        title: 'Contacts',
        path: '/contacts',
        icon: <ContactsIcon fontSize="small" />,
        children: [
          {
            title: 'Lists',
            path: '/contacts/lists',
          },
          {
            title: 'Import',
            path: '/contacts/import',
          },
        ],
      },
      {
        title: 'Virtual Phones',
        path: '/virtual-phones',
        icon: <PhoneIcon fontSize="small" />,
      },
      {
        title: 'Messages',
        path: '/messages',
        icon: <SpeakerNotesIcon fontSize="small" />,
      },
      {
        title: 'Templates',
        path: '/templates',
        icon: <AssignmentIcon fontSize="small" />,
      },
      {
        title: 'History Logs',
        path: '/history-logs',
        icon: <AccessTimeIcon fontSize="small" />,
      },
      {
        title: 'Account',
        icon: <AccountCircleIcon fontSize="small" />,
        path: '/dashboard/account',
      },
    ],
  },
];
