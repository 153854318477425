export const formatDateTime = (date: Date | string): string => {
  let parsedDate;

  if (date instanceof Date) {
    parsedDate = date;
  } else {
    // Intenta analizar la cadena de fecha si es un string
    const timestamp = Date.parse(date);
    if (!isNaN(timestamp)) {
      parsedDate = new Date(timestamp);
    } else {
      // Si no se puede analizar la cadena, devuelve un valor vacío
      return '';
    }
  }

  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
  const day = String(parsedDate.getDate()).padStart(2, '0');
  const hours = String(parsedDate.getHours()).padStart(2, '0');
  const minutes = String(parsedDate.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};
