import { api, List } from '@textpony/interface';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useSaveList = () => {
  const queryClient = useQueryClient();
  return useMutation<List, AxiosError, Partial<List>>(
    async (listData) => {
      const body = {
        ...listData,
      };

      const request = body.id
        ? api['lists'].patch(`/${listData.id}`, body)
        : api['lists'].post(``, body);

      return request.then((r: AxiosResponse) => r.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('lists');
      },
    }
  );
};
