import { api } from '@textpony/interface';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteContacts = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, string[]>(
    (ids: string[]) => {
      /**
       * @todo Instead of the multiple API requests probably is better to implement
       * batch action to delete contacts
       */
      const requests = ids.map((id) => api['contacts'].delete(`/${id}`));
      return Promise.all(requests).then(() => undefined);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
      },
    }
  );
};
