import { useAuth } from '@textpony/interface';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children: JSX.Element;
}

export const AuthGuard = ({ children }: Props) => {
  const { isAuthenticated, isInitialized } = useAuth();
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};
