import { api, Contact, unformatPhone } from '@textpony/interface';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useSaveContact = () => {
  const queryClient = useQueryClient();
  return useMutation<Contact, AxiosError, Contact>(
    async (contactData) => {
      const body = {
        ...contactData,
        phone: unformatPhone(contactData.phone),
      };

      const request = body.id
        ? api['contacts'].patch(`/${contactData.id}`, body)
        : api['contacts'].post(``, body);

      return request.then((r: AxiosResponse) => r.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
      },
    }
  );
};
