import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { api, useCurrentUser, Invoice } from '@textpony/interface';

export const useInvoice = (queryOptions: { id?: string }) => {
  const { id } = queryOptions;

  const cacheKey = ['users', id];
  const user = useCurrentUser();

  return useQuery<Invoice, AxiosError>(cacheKey, () => {
    return api['users']
      .get(`/${user?.id}/invoices/${id}`)
      .then((r: AxiosResponse) => r.data);
  });
};
