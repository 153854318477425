import { merge } from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import { ThemeSettings } from '@textpony/theme';

interface SettingsContext {
  settings: ThemeSettings;
  saveSettings: (settings: ThemeSettings) => void;
}

const initialSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: 'dark',
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        direction: 'ltr',
        responsiveFontSizes: true,
        theme: 'dark',
      };
    }
  } catch (err) {
    console.error(err);
  }

  return settings;
};

export const storeSettings = (settings: ThemeSettings) => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

export const SettingsContext = createContext<SettingsContext>({
  settings: initialSettings,
  saveSettings: (settings: ThemeSettings) => null,
});

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const SettingsProvider: React.FC<Props> = ({ children }) => {
  const [currentSettings, setCurrentSettings] = useState(initialSettings);

  const handleSaveSettings = (settings: ThemeSettings) => {
    const mergedSettings = merge({}, currentSettings, settings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoreSettings);
    }
  }, []);

  useEffect(() => {
    document.body.setAttribute(
      'dir',
      currentSettings.direction === 'rtl' ? 'rtl' : 'ltr'
    );
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{ settings: currentSettings, saveSettings: handleSaveSettings }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
