import { List } from '@textpony/interface';

export enum ContactStatus {
  active,
  optOut,
  blocked,
}

export type Contact = {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  lists?: List[];
  metadata?: object;
  status: ContactStatus;
  createdAt: string;
  updatedAt?: string;
  userId: string;
};

export type AssignListsToContact = {
  id: string;
  lists: string[];
};

export type RemoveListsFromContact = AssignListsToContact;
