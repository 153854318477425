import 'simplebar-react/dist/simplebar.min.css';
import React, { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';
import { styled } from '@mui/material/styles';

const ScrollbarRoot = styled(SimpleBar)(({ theme }) => ({
  '& .simplebar-scrollbar::before': {
    background:
      theme.palette.mode === 'light' ? 'black' : theme.palette.secondary.main,
  },
}));

export const Scrollbar = forwardRef(
  (
    props: { children: React.ReactNode; sx?: Record<string, unknown> },
    ref: React.Ref<SimpleBar>
  ) => {
    return <ScrollbarRoot ref={ref} {...props} />;
  }
);
