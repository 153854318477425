/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DataGrid as MUIDataGrid,
  getGridDateOperators,
  GridColDef,
  GridColumns,
  GridFilterModel,
  GridLinkOperator,
  GridRowsProp,
} from '@mui/x-data-grid';
import React, { useCallback, useMemo } from 'react';
import ColumnsPanel from './ColumnsPanel';
import Toolbar from './Toolbar';
import { Box, GlobalStyles, Tooltip } from '@mui/material';
import {
  formatDateTime,
  formatPhone,
  localStorage,
  useQueryParams,
} from '@textpony/interface';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

interface Props {
  id: string;
  columns: GridColumns;
  rows: GridRowsProp;
  totalRows: number;
  extraToolbarComponents?: React.FC[];
  selectionMode?: 'single' | 'multiple';
  onSelectionChange?: (selected: string[]) => void;
  selectedRows?: string[];
  loading?: boolean;
  multiplePagesSelect?: boolean;
  rowsPerPageOptions?: number[];
  isStatic?: boolean;
}

export function DataGrid({
  id,
  rows,
  columns,
  totalRows,
  extraToolbarComponents,
  selectionMode = 'multiple',
  selectedRows,
  onSelectionChange,
  multiplePagesSelect = false,
  loading,
  rowsPerPageOptions,
  isStatic,
}: Props) {
  const { getParam, changeQueryParams, clearParams } = useQueryParams();

  const columnVisibilityModelKey = `data-grid-columns-${id}`;
  const columnVisibilityModel = localStorage.get(columnVisibilityModelKey);

  // Pagination
  const page = Number(getParam('page'));
  const pageSize = Number(getParam('limit'));
  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      changeQueryParams({ limit: newPageSize.toString(), page: '0' });
    },
    [changeQueryParams]
  );
  const handlePageChange = useCallback(
    (newPage: number) => changeQueryParams({ page: `${newPage}` }),
    [changeQueryParams]
  );

  // Sorting
  const [sortField, sortDirection] = (getParam('sort') || '').split(',');
  const sortModel = useMemo(
    () =>
      sortField
        ? [
            {
              field: sortField,
              sort: sortDirection.toLowerCase(),
            },
          ]
        : [],
    [sortField, sortDirection]
  );

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      const sortBy = model[0];
      if (!sortBy) {
        sortModel.length > 0 && clearParams(['page', 'sort']);
        return;
      }

      const currentSort = sortModel[0];
      const hasChanged =
        !currentSort ||
        currentSort.sort !== sortBy.sort ||
        currentSort.field !== sortBy.field;

      if (!hasChanged) {
        return;
      }

      changeQueryParams({
        sort: [sortBy.field, sortBy.sort?.toUpperCase()].join(','),
        page: '0',
      });
    },
    [changeQueryParams, clearParams, sortModel]
  );

  // Filtering
  const allowedFilters = useMemo(
    () =>
      columns
        .filter((column) => column.filterable !== false)
        .map((column) => column.field),
    [columns]
  );
  const filters = useMemo(
    () =>
      (getParam('filter') || []).filter((filter: any) =>
        allowedFilters.includes(filter.columnField)
      ),
    [allowedFilters, getParam]
  );
  const currentFilterModel: GridFilterModel = useMemo(
    () => ({
      items: filters,
      linkOperator: GridLinkOperator.And,
    }),
    [filters]
  );
  const handleFilterModelChange = useCallback(
    (newFilterModel: any) => {
      changeQueryParams({
        filter: newFilterModel.items,
        page: '0',
      });
    },
    [changeQueryParams]
  );

  // Misc
  const handleColumnVisibilityChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      localStorage.set(columnVisibilityModelKey, model);
    },
    [columnVisibilityModelKey]
  );
  const enhancedColumns = useMemo(
    () =>
      columns.map((column) => {
        const columnConfig = {
          ...column,
          hide: (columnVisibilityModel || {})[column.field] === false,
          renderCell: column.renderCell
            ? column.renderCell
            : (data: GridRenderCellParams) => (
                <Tooltip title={data.formattedValue ?? ''}>
                  <Box
                    component="span"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data.formattedValue}
                  </Box>
                </Tooltip>
              ),
        };

        if (column.type === 'date') {
          columnConfig.filterOperators = getGridDateOperators().filter(
            (operator) => !['is', 'not'].includes(operator.value)
          );
        }

        return columnConfig;
      }),
    [columns, columnVisibilityModel]
  );

  return (
    <>
      <GlobalStyles
        styles={{
          // Hide the link operator selector as backend supports only AND operator
          '.MuiGridFilterForm-linkOperatorSelect': {
            display: 'none!important',
          },
          '.MuiDataGridPro-columnsPanel': {
            button: {
              display: 'hidden',
            },
          },
          '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'break-spaces',
            lineHeight: 'normal',
          },
        }}
      />
      <MUIDataGrid
        // Data model
        rows={rows}
        columns={enhancedColumns}
        // Selection
        checkboxSelection={
          selectionMode === 'single' ? false : !!onSelectionChange
        }
        // @ts-ignore
        onSelectionModelChange={onSelectionChange}
        keepNonExistentRowsSelected={
          selectionMode === 'multiple' && multiplePagesSelect
        }
        selectionModel={selectedRows}
        // Pagination
        pagination
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={
          rowsPerPageOptions ? rowsPerPageOptions : ROWS_PER_PAGE_OPTIONS
        }
        paginationMode="server"
        rowCount={totalRows}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        autoHeight={true}
        // Filtering
        filterMode="server"
        filterModel={currentFilterModel}
        onFilterModelChange={handleFilterModelChange}
        // Sorting
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        // Misc
        density="comfortable"
        loading={loading}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        componentsProps={{
          filterPanel: { linkOperators: ['and'] },
          toolbar: {
            extraComponents: extraToolbarComponents,
          },
        }}
        components={{ Toolbar: Toolbar, ColumnsPanel: ColumnsPanel }}
        sx={(theme) => ({
          borderColor: 'primary.light',
          '& .MuiDataGrid-columnsContainer, .MuiDataGrid-columnHeaders, .MuiDataGrid-cell':
            {
              borderBottom: `1px solid ${
                theme.palette.mode === 'light'
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light
              }`,
            },
        })}
        {...(isStatic
          ? {
              disableColumnFilter: true,
              disableColumnSelector: true,
              disableColumnMenu: true,
              hideFooter: true,
            }
          : {})}
      />
    </>
  );
}

export const dateTimeColumn = (config: GridColDef): GridColDef => ({
  ...config,
  type: 'dateTime',
  valueGetter: (params) => new Date(params.value),
  valueFormatter: ({ value }) => formatDateTime(value),
});

export const phoneColumn = (config: GridColDef): GridColDef => ({
  ...config,
  type: 'string',
  valueFormatter: ({ value }) => formatPhone(value),
});
