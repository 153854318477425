import React from 'react';
import { Box, Card, CardContent, Container } from '@mui/material';

const FormContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.default',
        height: '100%',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          py: {
            xs: '60px',
            md: '120px',
          },
        }}
      >
        <Card elevation={16} sx={{ px: 1 }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: 400,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {children}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default FormContainer;
