import jwt_decode from 'jwt-decode';
import React, { createContext, useEffect, useReducer } from 'react';
import {
  api,
  axiosInstance,
  IJWTDecoder,
  IToken,
  IUser,
  localStorage,
  log,
} from '@textpony/interface';

interface IAuthState {
  redirectUri?: string;
  isAuthenticated: boolean;
  isInitialized: boolean;
  user?: IUser;
}

const initialAuthState: IAuthState = {
  redirectUri: '/dashboard',
  isAuthenticated: false,
  isInitialized: false,
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const currentTime = Date.now() / 1000;
  return decodeJWT(accessToken).exp > currentTime;
};
const decodeJWT = (accessToken: string) => {
  return jwt_decode<IJWTDecoder>(accessToken);
};

const setSession = (accessTokens: IToken | null) => {
  if (accessTokens) {
    localStorage.set('accessTokens', JSON.stringify(accessTokens));
    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${accessTokens.access.token}`;
  } else {
    localStorage.rm('accessTokens');
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case 'UPDATE_USER': {
      const { user } = action.payload;
      return { ...state, user };
    }
    case 'LOGIN': {
      const { user, redirectUri } = action.payload;
      return {
        ...state,
        redirectUri: redirectUri ?? state?.redirectUri,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

// noinspection JSUnusedLocalSymbols
const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: (email: string, password: string, redirectUri?: string) =>
    Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  registerUser: (token: IToken, use: IUser) => null,
});

export const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (
    email: string,
    password: string,
    redirectUri?: string
  ) => {
    const response = await api['auth'].post('/login', { email, password });
    const { tokens, user } = response.data;

    log(redirectUri);

    setSession(tokens);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        redirectUri,
      },
    });
  };

  const logout = async () => {
    await api['auth'].post(
      '/logout',
      { email: state.user.email },
      {
        data: { skipAuthRefresh: true },
      }
    );
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const registerUser = async (token: IToken, user: IUser) => {
    setSession(token);
    dispatch({ type: 'UPDATE_USER', payload: { user } });
  };

  const updateAuthUser = (user: IUser) => {
    dispatch({ type: 'UPDATE_USER', payload: { user } });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const tokens: IToken = JSON.parse(localStorage.get('accessTokens'));

        if (tokens && isValidToken(tokens.access.token)) {
          setSession(tokens);

          const response = await axiosInstance.get(`/users/me`);
          const user = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  // TODO: add splash screen
  // if (!state.isInitialized) {
  //   return <SplashScreen />;
  // }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        registerUser,
        logout,
        updateAuthUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
