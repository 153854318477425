import { api } from '@textpony/interface';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

type Props = {
  amount: number;
  paymentMethodId: string;
};

export const useBuyCredits = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, Props>(
    async ({ amount, paymentMethodId }) => {
      return api['users'].post(`/wallet/deposit`, {
        amount,
        paymentMethodId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('balance');
        queryClient.invalidateQueries('invoices');
      },
    }
  );
};
