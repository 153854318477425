import React from 'react';
import { Alert, AlertTitle, Button } from '@mui/material';

interface ErrorFallbackProps {
  onReset: VoidFunction;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ onReset }) => {
  return (
    <Alert severity="error">
      <AlertTitle>Ups! Something went wrong!</AlertTitle>
      <Button onClick={onReset} variant="contained" color="error">
        Try again
      </Button>
    </Alert>
  );
};

export default ErrorFallback;
