import { AxiosError } from 'axios';
import set from 'lodash/set';
import { useQuery, UseQueryOptions } from 'react-query';
import { MessagesInsights } from '../../types';
import { QueryOptions } from '../query';

interface InsightMessagesInfo {
  data: MessagesInsights;
}

const MESSAGESINSIGHT = {
  id: '1',
  creditsSpent: 100,
  successfulMessages: 30,
  failedMessages: 5,
  createdAt: '2023-06-16T15:01:49.499Z',
  updatedAt: '2023-06-16T15:01:49.499Z',
};

export const useMessagesInsight = (
  queryOptions: QueryOptions,
  options: UseQueryOptions<InsightMessagesInfo, AxiosError> = {}
) => {
  const { filter, search, limit = 10, sort } = queryOptions;
  const params = { limit };
  if (filter) {
    set(params, 'filter', filter);
  }
  if (sort) {
    set(params, 'sort', sort);
  }
  if (search) {
    set(params, 's', JSON.stringify({ name: { $cont: search } }));
  }

  if (filter) {
    set(params, 'updatedAt', {
      $gte: filter.startDate,
      $lte: filter.endDate,
    });
  }

  const cacheKey = ['insight-messages', params];

  return useQuery<InsightMessagesInfo, AxiosError>(
    cacheKey,
    () => {
      /** @todo Connect to API to get conversation messages */
      return new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              data: MESSAGESINSIGHT,
            }),
          700
        );
      });
    },
    {
      ...options,
    }
  );
};
