import { Box, Button, Collapse, List, ListItem } from '@mui/material';
import {
  ChevronDown as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
} from '@textpony/interface';
import React, { useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { SidebarItem } from './interfaces';

interface Props extends Object {
  depth: number;
  item: SidebarItem;
}

const flattenChildrenPaths = (item: SidebarItem): string[] => {
  return (item.children || []).reduce(
    (result, child: SidebarItem) => result.concat(flattenChildrenPaths(child)),
    item.path ? [item.path] : []
  );
};

const SideBarItem: React.FC<Props> = ({ item, depth }) => {
  const { children, icon, path, title } = item;
  const location = useLocation();
  const isCurrentRoute = path === location.pathname;
  const [open, setOpen] = useState(
    flattenChildrenPaths(item).includes(location.pathname)
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const hasChildren = !!children;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'block',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
      key={title}
    >
      <Button
        component={path ? RouterLink : Button}
        to={path}
        endIcon={
          hasChildren ? (
            !open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronDownIcon fontSize="small" />
            )
          ) : undefined
        }
        disableRipple
        onClick={hasChildren && !path ? handleToggle : undefined}
        startIcon={icon}
        sx={{
          backgroundColor: isCurrentRoute ? 'action.selected' : undefined,
          borderRadius: 1,
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? isCurrentRoute
                ? 'secondary.main'
                : 'neutral.300'
              : isCurrentRoute
              ? 'secondary.main'
              : 'neutral.600', //modified
          fontWeight: isCurrentRoute ? 'fontWeightBold' : undefined,
          justifyContent: 'flex-start',
          pl: `${paddingLeft}px`,
          pr: 3,
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          '& .MuiButton-startIcon': {
            color: isCurrentRoute ? 'secondary.main' : 'neutral.400',
          },
          '&:hover': {
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'action.selected' : 'neutral.200',
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
      {hasChildren && (
        <Collapse in={open} sx={{ mt: 0.5 }}>
          <List disablePadding>
            {children.map((child) => (
              <SideBarItem key={child.title} depth={depth + 1} item={child} />
            ))}
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};

export default SideBarItem;
