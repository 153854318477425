import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Button, Card, Typography } from '@mui/material';

interface Props {
  title?: string;
  buttonTitle?: string;
  startValue: string | null;
  setStartValue: React.Dispatch<React.SetStateAction<string | null>>;
  endValue: string | null;
  setEndValue: React.Dispatch<React.SetStateAction<string | null>>;
}

export const DateRangePicker: React.FC<Props> = ({
  title = 'Select Date Range',
  buttonTitle = 'Set',
  startValue,
  setStartValue,
  endValue,
  setEndValue,
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const handleStartChange = (date: Moment | null) => {
    setStartDate(date);
  };

  const handleEndChange = (date: Moment | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setStartValue(null);
    setEndValue(null);
  };

  const handleSet = () => {
    if (!startDate || !endDate) {
      return;
    }
    setStartValue(startDate?.format('YYYY-MM-DD'));
    setEndValue(endDate?.format('YYYY-MM-DD'));
  };

  return (
    <Card sx={{ p: 2 }}>
      <Typography color="textSecondary" variant="body2">
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1.5,
          px: 1,
          py: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartChange}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndChange}
            minDate={startDate || undefined}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: 2,
            }}
            onClick={handleSet}
          >
            {buttonTitle}
          </Button>
          <Button variant="outlined" color="primary" onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
