import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { SpentHistory } from '../../types';
import { api } from '../../utils';

export const useSpentHistory = (
  options: UseQueryOptions<SpentHistory, AxiosError> = {}
) => {
  const cacheKey = ['balance'];

  return useQuery<SpentHistory, AxiosError>(
    cacheKey,
    () => {
      return api['users']
        .get(`/wallet/spending-history`)
        .then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
