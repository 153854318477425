import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { Balance } from '../../types';
import { api } from '../../utils';

export const useBalance = (
  options: UseQueryOptions<Balance, AxiosError> = {}
) => {
  const cacheKey = ['balance'];

  return useQuery<Balance, AxiosError>(
    cacheKey,
    () => {
      return api['users']
        .get(`/wallet/balance`)
        .then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
