import {
  Box,
  ButtonBase,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  styled,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link as RouterLink } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import {
  Cog as CogIcon,
  InitialsAvatar,
  useAuth,
  UserCircle as UserCircleIcon,
} from '@textpony/interface';

const AccountMenuItem = styled(RouterLink)(() => ({
  '&:hover': { textDecoration: 'none' },
}));

const Account: React.FC = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const { user, logout } = useAuth();

  if (!user) {
    return null;
  }

  const userName = [user.firstName, user.lastName]
    .filter((val) => !!val)
    .join(' ');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box component={ButtonBase} ref={ref} onClick={handleOpen} ml={3}>
        <InitialsAvatar name={userName} />
      </Box>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { width: 300 } }}
        transitionDuration={0}
      >
        <Box alignItems="center" p={2} display="flex">
          <InitialsAvatar name={userName} />
          <Box ml={1}>
            <Typography variant="subtitle1">{user.firstName}</Typography>
            <Typography color="textSecondary" variant="body2">
              Textpony
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box sx={{ my: 1 }}>
            <Link component={AccountMenuItem} to="/dashboard/account">
              <MenuItem>
                <ListItemIcon>
                  <UserCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" color="textPrimary">
                      Account
                    </Typography>
                  }
                />
              </MenuItem>
            </Link>
            <Link component={RouterLink} to="/settings">
              <MenuItem>
                <ListItemIcon>
                  <CogIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" color="textPrimary">
                      Settings
                    </Typography>
                  }
                />
              </MenuItem>
            </Link>
          </Box>
          <Divider />
          <MenuItem onClick={() => logout()} sx={{ my: 1 }}>
            <ListItemIcon sx={{ m: 0, pr: 2 }}>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Logout</Typography>}
            />
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default Account;
