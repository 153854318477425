import { AxiosError, AxiosResponse } from 'axios';
import set from 'lodash/set';
import { useQuery, UseQueryOptions } from 'react-query';
import { PhoneNumber, api } from '@textpony/interface';
import { QueryOptions } from '../query';

interface VirtualPhoneList {
  data: PhoneNumber[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export const useVirtualPhones = (
  queryOptions: QueryOptions,
  options: UseQueryOptions<VirtualPhoneList, AxiosError> = {}
) => {
  const { limit = 10, offset, sort } = queryOptions;
  const params = { limit };
  if (sort) {
    set(params, 'sort', sort);
  }
  if (offset) {
    set(params, 'offset', offset);
  }

  const cacheKey = ['list-phones', params];

  return useQuery<VirtualPhoneList, AxiosError>(
    cacheKey,
    () => {
      return api['numbers'].get('/', params).then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
