import { isEqual } from 'lodash';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Adjustments as SettingsIcon, X as XIcon } from '@textpony/interface';
import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSettings } from '@textpony/theme';

const Settings: React.FC = () => {
  const buttonRef = useRef();
  const { settings, saveSettings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });
  const [prevValues, setPrevValues] = useState(values);
  const [disabled, setDisabled] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field: string, value: string | boolean) => {
    setValues({
      ...values,
      [field]: value,
    });

    setDisabled(false);
  };

  const handleSave = () => {
    saveSettings(values);

    if (!isEqual(values, prevValues)) {
      enqueueSnackbar('Settings updated correctly', {
        variant: 'success',
      });

      setPrevValues(values);
    }

    setOpen(false);
    setDisabled(true);
  };

  return (
    <>
      <Tooltip title="Theme settings">
        {/* @ts-ignore-next-line */}
        <IconButton ref={buttonRef} onClick={handleOpen} sx={{ ml: 1 }}>
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{ sx: { width: 320 } }}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            display: 'flex',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}
        >
          <Typography color="inherit" variant="h6">
            Theme settings
          </Typography>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{ color: 'inherit' }}
          >
            <XIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box p={2}>
          <Box mt={2} px={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.direction === 'rtl'}
                  edge="start"
                  name="direction"
                  onChange={(event) =>
                    handleChange(
                      'direction',
                      event.target.checked ? 'rtl' : 'ltr'
                    )
                  }
                />
              }
              label="RTL"
            />
          </Box>
          <Box mt={2} px={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.responsiveFontSizes}
                  edge="start"
                  name="responsive"
                  onChange={(event) =>
                    handleChange('responsiveFontSizes', event.target.checked)
                  }
                />
              }
              label="Responsive Font Sizes"
            />
          </Box>
          <Box mt={2}>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  select
                  label="Theme"
                  name="theme"
                  variant="outlined"
                  fullWidth
                  SelectProps={{ native: true }}
                  value={values.theme}
                  onChange={(event) =>
                    handleChange('theme', event.target.value)
                  }
                >
                  {['Light', 'Dark'].map((option, index) => (
                    <option key={index} value={option.toLowerCase()}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </div>
            </form>
          </Box>
          <Box mt={2}>
            <Button
              onClick={handleSave}
              fullWidth
              variant="contained"
              disabled={disabled}
              sx={{
                textTransform: 'uppercase',
              }}
            >
              Save settings
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
