import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@textpony/interface';

interface Props {
  children: JSX.Element;
}

export const GuestGuard = ({ children }: Props) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};
