import { useCallback, useState } from 'react';

export function useToggle(
  defaultValue = false
): [flag: boolean, toggleFlag: VoidFunction] {
  const [flag, setFlag] = useState(defaultValue);

  const toggleFlag = useCallback(() => setFlag(!flag), [flag]);

  return [flag, toggleFlag];
}
