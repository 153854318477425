import {
  AppBar,
  Box,
  IconButton,
  styled,
  SvgIcon,
  Toolbar,
} from '@mui/material';
import { Menu as MenuIcon } from 'react-feather';
import React from 'react';
import Notifications from './Notifications';
import Settings from './Settings';
import Account from './Account';
import Chip from '@mui/material/Chip';
import { useBalance } from '@textpony/interface';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none',
      }),
}));

interface Props {
  onOpenSideBar: () => void;
}

const TopBar: React.FC<Props> = ({ onOpenSideBar }) => {
  const { data: balance } = useBalance({ keepPreviousData: true });

  return (
    <DashboardNavbarRoot>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          onClick={onOpenSideBar}
          sx={{
            display: { xs: 'block', lg: 'none' },
          }}
        >
          <SvgIcon fontSize="small" sx={{ color: 'neutral[500]' }}>
            <MenuIcon />
          </SvgIcon>
        </IconButton>
        <Box ml={2} flexGrow={1} />
        <Chip
          label={`Balance : ${balance?.available} credits`}
          color="primary"
          sx={{
            p: 1,
          }}
        />
        <Settings />
        <Notifications />
        <Account />
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default TopBar;
