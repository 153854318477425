import React from 'react';
import Tab, { TabProps } from '@mui/material/Tab';
import { Link } from 'react-router-dom';

interface Props extends TabProps {
  label?: string;
  value?: string | number;
  href?: string;
}

export const LinkTab: React.FC<Props> = ({ href, ...props }) => {
  // @ts-ignore-next-line
  return <Tab component={Link} to={href} {...props} />;
};
