import { useEffect } from 'react';

export function usePageTitle(title: string) {
  useEffect(() => {
    document.title = 'TextPhony | ' + title;
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, [title]);
}
