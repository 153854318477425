import React, { ComponentType } from 'react';
import ErrorBoundaryComponent from './ErrorBoundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { useAuth } from '../../hooks';
import { useNavigate } from 'react-router-dom';

interface ErrorBoundaryProps {
  children?: JSX.Element;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary();
  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <ErrorBoundaryComponent {...{ reset, navigate, logout }}>
      {children}
    </ErrorBoundaryComponent>
  );
};

export function withErrorBoundary<P extends object>(
  WrappedComponent: ComponentType<P>
) {
  function ComponentWithErrorBoundary(props: P) {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  }

  return ComponentWithErrorBoundary;
}
