import { api } from '@textpony/interface';
import { useMutation, useQueryClient } from 'react-query';
import { PaymentMethod } from '@textpony/interface';

export const useUpdatePaymentMethod = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (paymentMethod: PaymentMethod) =>
      api['payment-methods'].patch(`/${paymentMethod.id}`, {
        isDefault: true,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['payment-methods']);
      },
    }
  );
};
