interface IRecipient {
  recipients?: string[];
  lists?: string[];
  contacts?: string[];
}

export type Message = {
  id: string;
  type: string;
  content: string;
  status: number;
  metadata: IRecipient;
  mediaKey?: string;
  scheduledAt?: string;
  createdAt: string;
  updatedAt?: string;
  virtualPhone?: string;
};

export enum MessageStatus {
  draft,
  active,
  sent,
  queued,
  processing,
  failed,
  archived,
}

export enum MessageType {
  'sms' = 'sms',
  'viber' = 'viber',
  'whatsapp' = 'whatsapp',
  'twitter' = 'twitter',
  'facebook' = 'facebook',
  'linkedin' = 'linkedin',
  'messenger' = 'messenger',
  'telegram' = 'telegram',
  'signal' = 'signal',
  'instagram' = 'instagram',
}

export const messageOptions: { value: string; label: string }[] = [
  { value: MessageType.sms, label: 'SMS' },
  { value: MessageType.viber, label: 'Viber' },
  { value: MessageType.whatsapp, label: 'WhatsApp' },
  { value: MessageType.twitter, label: 'Twitter' },
  { value: MessageType.facebook, label: 'Facebook' },
  { value: MessageType.linkedin, label: 'LinkedIn' },
  { value: MessageType.messenger, label: 'Messenger' },
  { value: MessageType.telegram, label: 'Telegram' },
  { value: MessageType.signal, label: 'Signal' },
  { value: MessageType.instagram, label: 'Instagram' },
];

export type ConversationMessage = {
  id: string;
  recipient: string;
  number: string;
  credits: number;
  createdAt: string;
  type: MessageType;
  status: MessageStatus;
  interaction: string;
};
interface IRecipient {
  recipients?: string[];
  lists?: string[];
  contacts?: string[];
}
