/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { api, Contact, RemoveListsFromContact } from '@textpony/interface';
import { AxiosError } from 'axios';

export const useRemoveContactListsRelationship = () => {
  const queryClient = useQueryClient();
  return useMutation<Contact, AxiosError, any>(
    async (data: RemoveListsFromContact) => {
      return api['contacts'].delete(
        `/${data.id}/relationships/lists`,
        data.lists
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
      },
    }
  );
};
