import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const formatPhone = (phone: string): string =>
  phoneUtil.format(phoneUtil.parse(phone), PhoneNumberFormat.INTERNATIONAL);

export const unformatPhone = (phone: string) => {
  try {
    return phoneUtil.format(phoneUtil.parse(phone), PhoneNumberFormat.E164);
  } catch (e) {
    return null;
  }
};
