import { List, ListSubheader } from '@mui/material';
import React from 'react';
import { SidebarSection } from './interfaces';
import SideBarItem from './SideBarItem';

interface Props {
  section: SidebarSection;
}

const SideBarSection: React.FC<Props> = ({ section }) => {
  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 2.5,
            ml: 4,
            textTransform: 'uppercase',
          }}
        >
          {section.title}
        </ListSubheader>
      }
      key={section.title}
    >
      <List disablePadding>
        {section.items.map((item) => (
          <SideBarItem key={item.title} depth={0} item={item} />
        ))}
      </List>
    </List>
  );
};

export default SideBarSection;
