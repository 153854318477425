import { AxiosError, AxiosResponse } from 'axios';
import set from 'lodash/set';
import { useQuery, UseQueryOptions } from 'react-query';
import { Message } from '../../types';
import { QueryOptions } from '../query';
import { api } from '@textpony/interface';

interface ScheduledMessagesList {
  data: Message[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

const MESSAGES = [
  {
    id: '1',
    type: 'sms',
    content:
      'Dear {{firstName}} {{lastName}}, We are delighted to welcome you to ABC Corporation! As a valued member of our team, we believe that your expertise and dedication will greatly contribute to our success.',
    status: 3,
    metadata: { recipients: ['Recipient 1'] },
    createdAt: '2023-06-01T10:00:00.000Z',
    updatedAt: '2023-06-10T15:30:00.000Z',
    scheduledAt: '2023-06-12T09:45:00.000Z',
  },
  {
    id: '2',
    type: 'whatsApp',
    content:
      'Dear {{firstName}} {{lastName}}, We are delighted to welcome you to ABC Corporation! As a valued member of our team, we believe that your expertise and dedication will greatly contribute to our success.',
    status: 3,
    metadata: {
      lists: ['List 1', 'List 2'],
      contacts: ['Contact 1', 'Contact 2'],
    },
    createdAt: '2023-06-02T12:00:00.000Z',
    updatedAt: '2023-06-12T09:45:00.000Z',
    scheduledAt: '2023-06-12T09:45:00.000Z',
  },
];

export const useScheduledMessages = (
  queryOptions: QueryOptions,
  options: UseQueryOptions<ScheduledMessagesList, AxiosError> = {}
) => {
  const { filter, search, limit = 10, offset, sort } = queryOptions;
  const params = { limit, filter };
  if (filter) {
    set(params, 'filter', filter);
  }
  if (sort) {
    set(params, 'sort', sort);
  }
  if (offset) {
    set(params, 'offset', offset);
  }
  if (search) {
    set(params, 's', JSON.stringify({ name: { $cont: search } }));
  }

  if (!params.filter) {
    params.filter = {};
  }
  set(params, 'filter.status', 'queue');

  const cacheKey = ['messages', params];

  return useQuery<ScheduledMessagesList, AxiosError>(
    cacheKey,
    () => {
      /** @todo Connect to API to get conversation messages */
      return new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              data: MESSAGES,
              page: 1,
              total: MESSAGES.length,
              count: MESSAGES.length,
              pageCount: 1,
            }),
          700
        );
      });
      //return api['messages'].get('', params).then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
