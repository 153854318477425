/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { api } from '@textpony/interface';
import { AxiosError } from 'axios';

interface AddContactsRequest {
  listId: string;
  contacts: string[];
}

export const useAddContacts = () => {
  const queryClient = useQueryClient();
  return useMutation<AddContactsRequest, AxiosError, AddContactsRequest>(
    async (data: AddContactsRequest) => {
      return api['lists'].post(
        `/${data.listId}/relationships/contacts`,
        data.contacts.map((contactId) => ({ id: contactId }))
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('lists');
      },
    }
  );
};
