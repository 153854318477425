import {
  Avatar,
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {
  Bell as BellIcon,
  InitialsAvatar,
  MailOpen as MailOpenIcon,
  X as XIcon,
} from '@textpony/interface';
import { format, subHours } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

const now = new Date();

interface Notification {
  id: string;
  author?: string;
  email?: string;
  createdAt: number;
  name: string;
  description: string;
  read: boolean;
  type: string;
}

const data = [
  {
    id: '5e8883f1b51cc1956a5a1ec0',
    createdAt: subHours(now, 2).getTime(),
    name: 'Nike',
    description: 'New merchant application. Pending to approve.',
    read: false,
    type: 'new_merchant',
  },
  {
    id: 'bfb21a370c017acc416757c7',
    author: 'Santiago Pablo Ortiz',
    email: 'sportiz9109@gmail.com',
    createdAt: subHours(now, 2).getTime(),
    name: 'Nike',
    description: `Santiago Pablo Ortiz approved Nike merchant`,
    read: false,
    type: 'merchant_approved',
  },
];

const getNotificationContent: React.FC<Notification> = (notification) => {
  switch (notification.type) {
    case 'merchant_approved':
      return (
        <>
          {notification.author && (
            <ListItemAvatar sx={{ mt: 0.5 }}>
              <InitialsAvatar name={notification.author} />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Typography sx={{ mr: 0.5 }} variant="subtitle2">
                  Merchant approved!
                </Typography>
                <Typography sx={{ mr: 0.5 }} variant="body2">
                  {notification.description}
                </Typography>
              </Box>
            }
            secondary={
              <Typography color="textSecondary" variant="caption">
                {format(notification.createdAt, 'MMM dd, h:mm a')}
              </Typography>
            }
            sx={{ my: 0 }}
          />
        </>
      );
    case 'new_merchant':
      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              <BusinessCenterIcon fontSize="small" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Typography variant="subtitle2" sx={{ mr: 0.5 }}>
                  New merchant to approve
                </Typography>
                <Typography variant="body2">
                  {notification.description}
                </Typography>
              </Box>
            }
            secondary={
              <Typography color="textSecondary" variant="caption">
                {format(notification.createdAt, 'MMM dd, h:mm a')}
              </Typography>
            }
            sx={{ my: 0 }}
          />
        </>
      );
    default:
      return null;
  }
};

const Notifications: React.FC = () => {
  const anchorRef = useRef(null);
  const [unread, setUnread] = useState(data.length);
  const [openPopover, setOpenPopover] = useState(false);
  const [notifications, setNotifications] = useState(data);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications((prevState) =>
      prevState.map((notification) => ({
        ...notification,
        read: true,
      }))
    );
  };

  const handleRemoveOne = (notificationId: string) => {
    setNotifications((prevState) =>
      prevState.filter((notification) => notification.id !== notificationId)
    );
  };

  useEffect(() => {
    const notRead = notifications.reduce(
      (acc, notification) => acc + (notification.read ? 0 : 1),
      0
    );

    setUnread(notRead);
  }, [notifications]);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton ref={anchorRef} onClick={handleOpenPopover} sx={{ ml: 1 }}>
          <Badge color="error" badgeContent={unread}>
            <SvgIcon fontSize="small">
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={handleClosePopover}
        open={openPopover}
        PaperProps={{ sx: { width: 380 } }}
        transitionDuration={0}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            display: 'flex',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}
        >
          <Typography color="inherit" variant="h6">
            Notifications
          </Typography>
          <Tooltip title="Mark all as read">
            <IconButton
              onClick={handleMarkAllAsRead}
              size="small"
              sx={{ color: 'inherit' }}
            >
              <MailOpenIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        {notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <List disablePadding>
            {notifications.map((notification) => (
              <ListItem
                divider
                key={notification.id}
                sx={{
                  alignItems: 'flex-start',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                  '& .MuiListItemSecondaryAction-root': {
                    top: '24%',
                  },
                }}
                secondaryAction={
                  <Tooltip title="Remove">
                    <IconButton
                      edge="end"
                      onClick={() => handleRemoveOne(notification.id)}
                      size="small"
                    >
                      <XIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Tooltip>
                }
              >
                {getNotificationContent(notification)}
              </ListItem>
            ))}
          </List>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
