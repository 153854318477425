import React, { Component } from 'react';
import ErrorFallback from './ErrorFallback';
import { log } from '@textpony/interface';

interface ErrorBoundaryComponentProps {
  children?: JSX.Element;
  reset: VoidFunction;
  navigate: (url: string) => void;
  logout: VoidFunction;
}

interface State {
  hasError: boolean;
}

interface BoundaryError extends Error {
  status?: number;
  data?: any;
}

class ErrorBoundary extends Component<ErrorBoundaryComponentProps, State> {
  public override state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: BoundaryError): State {
    switch (error.status) {
      case 401:
      case 400:
      case 422:
        return { hasError: false };
      default:
        return { hasError: true };
    }
  }

  public override componentDidCatch(error: BoundaryError) {
    if (error.status === 401) {
      const { logout, navigate } = this.props;
      logout();
      navigate('/login');
      return;
    }

    log(error);
  }

  public resetErrorBoundary() {
    this.props.reset();
    this.setState({ hasError: false });
  }

  public override render() {
    if (this.state.hasError) {
      return <ErrorFallback onReset={() => this.resetErrorBoundary()} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
