import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SideBar from './SideBar';
import TopBar from './TopBar';
import { Scrollbar } from '@textpony/interface';
import { Box } from '@mui/material';

const Root = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}));

const ContentContainer = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minHeight: 'calc(100vh - 64px)',
  overflow: 'auto',
}));

export const DashboardLayout: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isSidebarOpen, setSidebarState] = useState(false);

  return (
    <Root>
      <TopBar onOpenSideBar={() => setSidebarState(true)} />
      <SideBar onClose={() => setSidebarState(false)} open={isSidebarOpen} />
      <ContentWrapper>
        <ContentContainer>
          <Content>
            <Scrollbar
              sx={{
                height: 'calc(100vh - 64px)',
                backgroundColor: 'background.default',
              }}
            >
              <Box
                sx={{
                  p: 2,
                }}
              >
                {children}
              </Box>
            </Scrollbar>
          </Content>
        </ContentContainer>
      </ContentWrapper>
    </Root>
  );
};
