import { PhoneNumber, api } from '@textpony/interface';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';

type Params = {
  number: string;
  paymentMethod: string;
};

export const useBuyVirtualPhone = () => {
  const queryClient = useQueryClient();

  return useMutation<PhoneNumber, AxiosError, Params>(
    async (params) => {
      try {
        const response = await api['numbers'].post('', {
          number: params.number,
          paymentMethod: params.paymentMethod,
        });

        return response.data;
      } catch (error: any) {
        throw new Error('Error buying virtual phone: ' + error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('phoneNumbers');
      },
    }
  );
};
