import { useContext } from 'react';

import AuthContext from '../contexts/AuthContext';
import { IUser } from '../interfaces';

export const useAuth = () => useContext(AuthContext);

export const useCurrentUser = (): IUser => {
  const { user } = useAuth();

  if (!user) {
    throw new Error('Is not authenticated');
  }

  return user;
};
