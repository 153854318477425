import { Navigate, Route as RouteComponent, Routes } from 'react-router-dom';
import React, { lazy, Suspense, useMemo } from 'react';
import {
  AuthGuard,
  AuthLayout,
  DashboardLayout,
  GuestGuard,
  LoadingScreen,
  usePageTitle,
} from '@textpony/interface';

interface Route {
  path: string;
  name: string;
  guard?: React.ComponentType<{ children: JSX.Element }>;
  layout?: React.ComponentType;
  component: React.ComponentType;
}

const Wrapper = ({ children }: { children: JSX.Element }) => {
  return children;
};

const routeComponentFactory = (route: Route): React.FC => {
  return () => {
    const Guard = route.guard || Wrapper;
    const Layout = route.layout || Wrapper;
    const Component = route.component;

    usePageTitle(route.name);

    return (
      <Guard>
        <Layout>
          <Component />
        </Layout>
      </Guard>
    );
  };
};

export const renderRoutes = (routes: Route[]) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route) => {
          const Component = useMemo(
            () => routeComponentFactory(route),
            [route]
          );

          return (
            <RouteComponent
              key={route.path}
              path={route.path}
              element={<Component />}
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

export const routes = [
  {
    path: 'login',
    name: 'Login',
    guard: GuestGuard,
    layout: AuthLayout,
    component: lazy(() => import('@panel/views/auth/LoginView')),
  },
  {
    path: 'register',
    name: 'Register',
    guard: GuestGuard,
    layout: AuthLayout,
    component: lazy(() => import('@panel/views/auth/RegisterView')),
  },
  {
    path: 'forgot-password',
    name: 'Forgot password',
    guard: GuestGuard,
    layout: AuthLayout,
    component: lazy(() => import('@panel/views/auth/ForgotPasswordView')),
  },
  {
    path: 'reset-password/*',
    name: 'Reset Password',
    layout: AuthLayout,
    guard: GuestGuard,
    component: lazy(() => import('@panel/views/auth/ResetPasswordView')),
  },
  {
    path: '/',
    name: 'Home',
    guard: AuthGuard,
    component: () => <Navigate to="/dashboard" />,
  },
  {
    path: 'dashboard',
    name: 'Dashboard',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/dashboard')),
  },
  {
    path: 'dashboard/account',
    name: 'Account',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/profile')),
  },
  {
    path: 'templates',
    name: 'Templates',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/templates')),
  },
  {
    path: 'messages',
    name: 'Messages',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/messages')),
  },
  {
    path: 'messages/:id',
    name: 'Messages',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/messages')),
  },
  {
    path: 'messages/contacts=:contacts',
    name: 'Messages',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/messages')),
  },
  {
    path: 'messages/template=:template',
    name: 'Messages',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/messages')),
  },
  {
    path: 'messages/:data',
    name: 'Messages',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/messages/ScheduledMessages')),
  },
  {
    path: 'contacts',
    name: 'Contacts',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/contacts')),
  },
  {
    path: 'contacts/lists',
    name: 'Contact lists',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/contacts')),
  },
  {
    path: 'virtual-phones',
    name: 'Virtual phones',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/virtualPhones')),
  },
  {
    path: 'contacts/import',
    name: 'Import contacts',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/contacts')),
  },
  /* @todo Is better to use nested routes here */
  {
    path: '/history-logs',
    name: 'History Logs',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/historyLog')),
  },
  {
    path: '/history-logs/:tab',
    name: 'History Logs',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('@panel/views/historyLog')),
  },
  {
    path: 'privacy-policy',
    name: 'Privacy policy',
    layout: DashboardLayout,
    component: lazy(() => import('@panel/views/info/PrivacyPolicy')),
  },
  {
    path: 'terms-of-use',
    name: 'Terms of use',
    layout: DashboardLayout,
    component: lazy(() => import('@panel/views/info/TermsOfUse')),
  },
  {
    path: '*',
    name: '404 Not found',
    component: lazy(() => import('@panel/views/pageNotFound')),
  },
];

export default routes;
