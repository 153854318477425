import { Avatar } from '@mui/material';
import React from 'react';

interface Props {
  name: string;
}

export const InitialsAvatar: React.FC<Props> = ({ name }) => {
  const initials = name
    .split(' ')
    .map((part) => part.charAt(0))
    .filter((val) => !!val)
    .join('');
  return <Avatar alt={name}>{initials}</Avatar>;
};
