import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { TotalInsightsStats } from '../../types';
import { api } from '@textpony/interface';

interface InsightStats {
  data: TotalInsightsStats;
}

const totalInsightsStats = {
  totalSpent: 3000,
  totalWhatsappMessages: 800,
  totalSMS: 10000,
};

export const useTotalInsight = (
  options: UseQueryOptions<InsightStats, AxiosError> = {}
) => {
  const cacheKey = ['totalInsights'];

  return useQuery<InsightStats, AxiosError>(
    cacheKey,
    () => {
      /** @todo Connect to API to get conversation messages */
      return new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              data: totalInsightsStats,
            }),
          700
        );
      });
      //return api['messages'].get('', params).then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
