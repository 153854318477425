import { api, Message } from '@textpony/interface';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

export const useSaveMessage = () => {
  const saveMessageMutation = useMutation<
    Message,
    AxiosError,
    Partial<Message>
  >(async (messageData) => {
    const body = {
      ...messageData,
    };

    const request = body.id
      ? api['messages'].patch(`/${body.id}`, {
          status: 1,
          phoneId: body.virtualPhone,
        })
      : api['messages'].post('', body);

    return request.then((r: AxiosResponse) => r.data);
  });

  return saveMessageMutation;
};
