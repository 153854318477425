import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { PaymentMethodsResponse } from '@textpony/interface';
import { api } from '@textpony/interface';

export const usePaymentMethods = (
  options: UseQueryOptions<PaymentMethodsResponse, AxiosError> = {}
) => {
  const cacheKey = ['payment-methods'];

  return useQuery<PaymentMethodsResponse, AxiosError>(
    cacheKey,
    () => {
      return api['payment-methods'].get('/').then((r: AxiosResponse) => r.data);
    },
    {
      ...options,
    }
  );
};
