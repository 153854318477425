import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import unset from 'lodash/unset';
import qs from 'qs';
import React, { createContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface QueryParamsContextValue {
  getParam: (name: string) => any;
  getAllParams: () => Record<string, any>;
  changeQueryParams: (params: Record<string, any>) => void;
  clearParam: (name: string) => void;
  clearParams: (names: string[]) => void;
}
// @ts-ignore
const QueryParamsContext = createContext<QueryParamsContextValue>(null);

interface QueryParamsProviderProps {
  defaultParams: Record<string, any>;
  children: React.ReactNode;
}

export const QueryParamsProvider = ({
  defaultParams,
  children,
}: QueryParamsProviderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const changeQueryParams = (params: Record<string, string>) => {
    const newQueryParams = cloneDeep(queryParams);

    Object.keys(params).forEach((name) => {
      const value = params[name];
      if (value === defaultParams[name]) {
        if (has(newQueryParams, name)) {
          unset(newQueryParams, name);
        }
      } else {
        set(newQueryParams, name, value);
      }
    });

    const newSearch = qs.stringify(newQueryParams, {
      addQueryPrefix: true,
      encode: false,
    });

    if (newSearch !== location.search) {
      navigate({
        search: newSearch,
      });
    }
  };

  const getParam = (name: string) => {
    return get(queryParams, name, defaultParams[name]);
  };

  const getAllParams = () => {
    const values: Record<string, string> = {};
    Object.keys(defaultParams).forEach((name) => {
      set(values, name, getParam(name));
    });
    return values;
  };

  const clearParam = (name: string) => {
    changeQueryParams({ [name]: defaultParams[name] });
  };

  const clearParams = (names: string[]) => {
    changeQueryParams(
      names.reduce(
        (result, name) => ({ ...result, [name]: defaultParams[name] }),
        {}
      )
    );
  };

  const value: QueryParamsContextValue = {
    getParam,
    getAllParams,
    changeQueryParams,
    clearParam,
    clearParams,
  };

  return (
    <QueryParamsContext.Provider value={value}>
      {children}
    </QueryParamsContext.Provider>
  );
};

export default QueryParamsContext;
