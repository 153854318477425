/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { api, AssignListsToContact } from '@textpony/interface';
import { AxiosError } from 'axios';

export const useSetContactListsRelationship = () => {
  const queryClient = useQueryClient();
  return useMutation<AssignListsToContact, AxiosError, any>(
    async (data: AssignListsToContact) => {
      return api['contacts'].patch(
        `/${data.id}/relationships/lists`,
        data.lists
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
      },
    }
  );
};
